.app-header {
    display: flex;
    flex-direction: column;
    
    .nav {
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px 29px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        width: 100vw;
        background-color: #024959;
        color: #F2E3D5;

        a {
            margin: 0px 20px;
            color: #F2E3D5;
            text-decoration: none;
        }

        a:last-child {
            margin-right: 0px;
        }
    }
}