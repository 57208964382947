.page.create {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 60vh;

    .demo {
        max-height: 80% !important;
        max-width: 80% !important;
    }
}

.settings {
    box-shadow: 0px -2px 7px 0px #999999 inset;
    width: 100%;
    background-color: rgba(218, 218, 218, 0.322);
    overflow-x: auto;

    hr {
        margin: 20px;
        border-top: 1px solid #d4d4d4;
    }
    
    .settings-object,
    .settings-shadows {
        padding: 20px 20px 20px 20px;

        label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    .settings-object {
        
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .heading {
            display: flex;
            flex-direction: row;
            
            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }
        
    }

    .settings-shadows {

        .heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;

            button[type="button"] {
                background-color: inherit;
            }

            span {
                margin-left: 10px;
                font-weight: bold;
            }
        }
        .shadowForm {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            box-shadow: 1px 1px 3px 0px #999999;
            padding: 10px;

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }
    }

    .settings-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 30px;

        button[type="button"] {
            margin: 20px;
            background-color: #024959;
            color: #ffffff;
            border-radius: 5px;
            padding: 10px;
        }
    }
    
}

button[type="button"] {
    border: none;
}

select {
    border: 1px solid #b1b1b1;
    border-left: 0px;
    padding: 3px 1px;
}
input[type="number"] {
    border: 1px solid #b1b1b1;
    padding: 4px;
    border-right: 0px;
    text-align: right;
}


@media only screen and (min-width: 760px){
    .app-content {
        overflow-y: hidden;
    }
    .page.create {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        
    }   

    .canvas {
        height: 100%;
        flex: 1;
    }
    .settings {
        overflow-y: auto;
        width: 400px !important;
        background-color: rgba(218, 218, 218, 0.322);
    }

}