:root {
    --primary-font-family: 'Roboto';
}

* {
    margin: 0px;
    padding: 0px;
    font-family: var(--primary-font-family);
    font-weight: 300;
    box-sizing: border-box;
}

html, 
body,
#root,
.app {
    height: 100vh;
    width: 100vw;
}

.app {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 360px;
}

.app-content {
    overflow-x: auto;
}

button {
    padding: 3px 6px;
}

input[type=number],
select {
    padding: 3px 6px;
}

label {
    margin-bottom: 3px;

    span {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 760px){
    #root {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .app-content {
        width: 100%;
        height: 100%;
        align-self: center;
        display: flex;
        justify-content: center;
    }
}